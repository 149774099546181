import { INewCollectionRepository } from '@modules/collections/application/ports/newCollection.repository.port';
import { CollectionWithRecipeResponse, NewCollectionQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { right } from '@shared/utils/either.utils';
import { NewCollectionEntityMapper } from '../../outbound/ports/newCollection.mapper';
export class NewCollectionRepositoryImpl implements INewCollectionRepository {
  async getNewCollection(): INewCollectionRepository.getNewCollectionOutput {
    try {
      const {
        data,
        errors
      } = await gqlQuery({
        query: NewCollectionQueryDocument
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      const output = data.getHighlightCollection.map((collection: CollectionWithRecipeResponse) => NewCollectionEntityMapper.toDomain(collection));
      return right(output);
    } catch (error: any) {
      console.error('error NewCollectionRepositoryImpl', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
}